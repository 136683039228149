//
// forms.scss
//

// Form-control light
.form-control-light {
    background-color: #{map-get($grays, "100")} !important;
    border-color: #{map-get($grays, "100")} !important;
}


// Form elements (Color and Range)
input.form-control[type="color"],
input.form-control[type="range"] {
    min-height: 39px;
}


// Custom select
.custom-select.is-invalid, .form-control.is-invalid,.custom-select:invalid, .form-control:invalid,
.custom-select.is-valid, .form-control.is-valid, .custom-select:valid,.form-control:valid {
    &:focus {
        box-shadow: none !important;
    }
}

select.form-control {
    &:not([size]):not([multiple]) {
        height: $input-height;
    }
}

select.form-control-sm {
&:not([size]):not([multiple]) {
    height: $input-height-sm;
}
}

// Show Password
.password-eye {
    &:before {
        font-family: "Material Design Icons";
        content: "\F06D0";
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        vertical-align: middle;
        line-height: 1.2;
        font-size: 16px;
    }

    &:hover {
      cursor: pointer;
    }
}

.show-password {
    .password-eye {
        &:before {
            content: "\F06D1";
        }
    }
}



// Dark mode
body[data-layout-color="dark"] {
    .form-control-light {
        background-color: #{map-get($dark-grays, "100")} !important;
        border-color: #{map-get($dark-grays, "100")} !important;
    }
}
